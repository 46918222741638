<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        الرموز التحليلية
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
        
          <b-col cols="12">
            <b-form-group
              label="Google كود"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.google" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Facebook كود"
              label-for="blog-edit-course"
              class="mb-2"
            >
             
                <b-form-input v-model="addCourseForm.facebook" />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";

export default {
  
  setup() {
     const form=ref({
      google:'',
      facebook:''
     })
const addCourseForm =reactive(form)
    let data=["facebook_code",
        "google_code"]
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
     store.dispatch("setting/GetSettingArray", formData).then((response) => {
  console.log("data",response)
  // form.value=response.data
  form.value.google=response.data[0].value
   form.value.facebook=response.data[1].value
    });
    const addCourseFormvalidate = ref();
   

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();
  
      let data=[{
        name:'facebook_code',
        value:addCourseForm.value.facebook
      },
      {
        name:'google_code',
         value:addCourseForm.value.google
      }
      
      ]
    
      formData.append("data", JSON.stringify(data));

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          
          store
            .dispatch("setting/updateSettingArray", formData)
            .then((response) => {
              Vue.swal({
                icon: "success",
              title: "تم التحديث",
              confirmButtonText: "حسنا",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "حسنا",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
   
    return {
     
  
      addCourseForm,
      form,
  
      addCourseFormvalidate,
  

      save,
    
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
  .col-12 {
    margin: 0px !important;
  }
  
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
